<template>
  <v-dialog
    dark
    fullscreen
    persistent
    style="background-color: white"
    @input="$emit('input')"
    :value="value"
  >
    <v-card>
      <div style="touch-action: none">
        <div class="mx-4 my-4 d-flex">
          <v-btn class="ml-auto" icon @click="$emit('input')">
            <v-icon size="35">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-tabs-items touchless dark v-model="tab">
          <v-tab-item value="login-1">
            <div class="mx-4">
              <div style="font-size: 25px; font-weight: bold">
                {{ $t("login.login") }}
              </div>
              <v-text-field
                style="font-size: 18px"
                height="30"
                :label="$t('register.email')"
                v-model="login.email"
                class="mt-4"
                autocapitalize="off"
              ></v-text-field>
              <v-btn class="mt-3" color="#324057" @click="onLoginNextClick" large block
                >Next</v-btn
              >
              <div class="d-flex mt-6">
                <v-divider class="my-auto" color="#ffffff"></v-divider>
                <div class="mx-2">{{ $t("common.or") }}</div>
                <v-divider class="my-auto" color="#ffffff"></v-divider>
              </div>
              <v-btn @click="onRegisterPageClick" color="#274a2e" class="mt-6" large block
                >Register</v-btn
              >
            </div>
          </v-tab-item>
          <v-tab-item value="login-2">
            <div class="mx-4">
              <div class="d-flex">
                <v-btn icon @click="() => (tab = 'login-1')">
                  <v-icon size="40">mdi-chevron-left</v-icon>
                </v-btn>
              </div>
              <div class="mt-3" style="font-size: 25px; font-weight: bold">
                {{ $t("login.enterPassword") }}
              </div>
              <v-text-field
                style="font-size: 18px"
                height="30"
                :label="$t('login.password')"
                v-model="login.password"
                type="password"
                class="mt-4"
                autocapitalize="off"
              ></v-text-field>
              <v-btn @click="onLogin" class="mt-3" color="#324057" block large
                >Login</v-btn
              >
            </div>
          </v-tab-item>
          <v-tab-item value="register-1">
            <div class="mx-4">
              <div class="d-flex">
                <v-btn icon @click="() => (tab = 'login-1')">
                  <v-icon size="40">mdi-chevron-left</v-icon>
                </v-btn>
              </div>
              <div class="mt-3" style="font-size: 25px; font-weight: bold">
                {{ $t("register.title") }}
              </div>
              <v-text-field
                v-model="register.email"
                :label="$t('register.email')"
                autocapitalize="off"
              ></v-text-field>
              <div class="d-flex">
                <v-text-field
                  class="mr-2"
                  v-model="register.firstName"
                  :label="$t('register.firstName')"
                  autocapitalize="off"
                ></v-text-field>
                <v-text-field
                  class="ml-2"
                  v-model="register.lastName"
                  :label="$t('register.lastName')"
                  autocapitalize="off"
                ></v-text-field>
              </div>
              <v-text-field
                v-model="register.password"
                hint="Password must contain at least 1 upper case letter, lower case letter and numeric character"
                type="password"
                :label="$t('register.password')"
                autocapitalize="off"
              ></v-text-field>
              <v-text-field
                hide-details
                class="mt-3"
                v-model="register.confirmPassword"
                type="password"
                :label="$t('register.confirmPassword')"
                autocapitalize="off"
              ></v-text-field>
              <v-btn @click="onRegisterNextClick" large class="mt-12" block>Next</v-btn>
            </div>
          </v-tab-item>
          <v-tab-item value="register-2">
            <div class="mx-4">
              <div class="d-flex">
                <v-btn icon @click="() => (tab = 'login-1')">
                  <v-icon size="40">mdi-chevron-left</v-icon>
                </v-btn>
              </div>
              <div class="mt-3" style="font-size: 25px; font-weight: bold">
                {{ $t("register.emailVerify") }}
              </div>
              <div class="mt-2" style="color: #9c9c9c">
                The verification code has been sent to your email {{ register.email }},
                The code will be valid for 30 minutes.
              </div>
              <v-otp-input
                v-model="register.verifyCode"
                class="mt-3"
                length="6"
              ></v-otp-input>
              <v-btn class="mt-4" @click="onRegister" block large>Sumbit</v-btn>

              <div class="mt-2" style="font-size: 14px; color: #9c9c9c">
                {{ $t("register.agree1") }} {{ $t("register.agree2") }}
                {{ $t("register.agree3") }} {{ $t("register.agree4") }}
              </div>
            </div>
          </v-tab-item>
          <v-tab-item value="success-1">
            <div class="mx-4">
              <div class="d-flex flex-column">
                <div style="font-size: 35px" class="mx-auto text-center">Success</div>
                <img
                  style="width: 100px"
                  class="mx-auto mt-4"
                  :src="require('../assets/check.png')"
                />
                <div style="font-size: 18px" class="mx-auto mt-8">
                  Your account has been created.
                </div>
                <v-btn class="mt-4" @click="tab = 'login-1'">Go back Login</v-btn>
              </div>
            </div></v-tab-item
          >
        </v-tabs-items>
      </div>
    </v-card>
    <v-overlay :value="isLoading">
      <v-progress-circular
        color="blue darken-4"
        indeterminate
        size="40"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>
<script>
import axios from "../axios";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      tab: "login-1",
      isLoading: false,
      register: {
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        confirmPassword: "",
        verifyCode: "",
      },
      login: {
        email: "",
        password: "",
      },
      username: "",
      password: "",
    };
  },
  props: ["value"],
  components: {},
  computed: {},
  methods: {
    ...mapMutations(["snackbarSetter", "authSetter"]),
    async onLoginNextClick() {
      if (!/.+@.+\..+/.test(this.login.email)) {
        this.snackbarSetter({
          success: false,
          message: this.$t("login.emailInvalid"),
          visible: true,
        });
        return;
      }
      this.tab = "login-2";
    },
    async onLogin() {
      this.isLoading = true;
      let res = await axios
        .create({
          baseURL: process.env.VUE_APP_BACKENDAPI,
        })
        .post("/user/login", {
          email: this.login.email,
          password: this.login.password,
        });
      this.isLoading = false;
      if (!res.data.status) {
        this.snackbarSetter({
          success: false,
          message: this.$t("login.wrongPass"),
          visible: true,
        });
        return;
      }
      this.authSetter({ isLoggedIn: true, token: res.data.token });
      this.snackbarSetter({
        success: true,
        message: this.$t("login.loginSuccess"),
        visible: true,
      });
      this.$emit("input");
    },
    async onRegisterNextClick() {
      if (!/.+@.+\..+/.test(this.register.email)) {
        this.snackbarSetter({
          success: false,
          message: this.$t("register.wrongEmail"),
          visible: true,
        });
        return;
      }
      if (this.register.firstName === "") {
        this.snackbarSetter({
          success: false,
          message: this.$t("register.emptyFisrtName"),
          visible: true,
        });
        return;
      }
      if (this.register.lastName === "") {
        this.snackbarSetter({
          success: false,
          message: this.$t("register.emptyLastName"),
          visible: true,
        });
        return;
      }
      if (
        !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}/.test(this.register.password)
      ) {
        this.snackbarSetter({
          success: false,
          message: this.$t("register.strongPasswordHints"),
          visible: true,
        });
        return;
      }
      if (this.register.password != this.register.confirmPassword) {
        this.snackbarSetter({
          success: false,
          message: this.$t("register.passwordNotMatch"),
          visible: true,
        });
        return;
      }
      this.isLoading = true;
      let res = await axios
        .create({
          baseURL: process.env.VUE_APP_BACKENDAPI,
        })
        .post(`/user/getEmailVerifyCode`, {
          firstName: this.register.firstName,
          lastName: this.register.lastName,
          email: this.register.email,
        });
      this.isLoading = false;
      if (res.data.status) {
        this.tab = "register-2";
      } else {
        this.snackbarSetter({
          success: false,
          message: this.$t("register.emailUsed"),
          visible: true,
        });
      }
    },
    async onRegister() {
      this.isLoading = true;
      let res = await axios
        .create({
          baseURL: process.env.VUE_APP_BACKENDAPI,
        })
        .post(`/user/register`, {
          email: this.register.email,
          firstName: this.register.firstName,
          lastName: this.register.lastName,
          password: this.register.password,
          verifyCode: this.register.verifyCode,
        });
      this.isLoading = false;
      if (!res.data.status) {
        if (res.data.message == "invalidVerifyCode") {
          this.snackbarSetter({
            success: false,
            message: this.$t("register.invalidVerifyCode"),
            visible: true,
          });
        } else if (res.data.message == "emailExists") {
          this.snackbarSetter({
            success: false,
            message: this.$t("register.emailUsed"),
            visible: true,
          });
        }
        return;
      }
      this.tab = "success-1";
      this.register = {
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        confirmPassword: "",
        verifyCode: "",
      };
    },
    close() {
      this.emit("input");
    },
    onRegisterPageClick() {
      this.tab = "register-1";
    },
  },
  watch: {
    value: function () {
      this.tab = "login-1";
    },
  },
  created() {
    
  },
  mounted() {},
};
</script>
<style scoped>
.textfield-input {
  color: #000000;
  border-style: solid;
  border-width: 1px;
  border-color: #8c8c8c;
  border-radius: 0.7rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.6rem;
  font-size: 1.2rem;
}
</style>
